.roi-cal {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-family: Lato, Arial, sans-serif;
  font-size: 62.5%;
  justify-content: center;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}
.roi-cal * {
  box-sizing: border-box;
}
.roi-cal__inner {
  font-size: 22px;
  min-height: 378px;
  overflow: hidden;
  padding: 20px;
  width: 320px;
  z-index: 4;
}
.roi-cal__inner:last-of-type {
  display: none;
}
@media (min-width: 992px) {
  .roi-cal__inner:last-of-type {
    display: block;
  }
}
.roi-cal__overlay {
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.roi-cal__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.roi-cal__wrap {
  border: 0;
  width: 100%;
}
.roi-cal__title {
  font-size: 30px;
  text-align: center;
}
.roi-cal__other-month {
  visibility: hidden;
}
.roi-cal__week-day-col {
  background: transparent;
  border: 0;
  padding: 0;
}
.roi-cal__week-day {
  border: 0 !important;
  font-size: 16px;
  line-height: 50px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  width: 40px;
}
.roi-cal__day {
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 40px;
}
.roi-cal__day:hover {
  text-decoration: none;
}
.roi-cal__day--nights {
  position: relative;
}
.roi-cal__day--nights::before,
.roi-cal__day--nights::after {
  bottom: 100%;
  left: -10px;
  margin-bottom: 5px;
  opacity: 0;
  position: absolute;
  transform: translate(-50%);
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  white-space: nowrap;
}
.roi-cal__day--nights::before {
  border-radius: 5px;
  content: attr(data-tooltip);
  font-size: 14px;
  padding: 1px 7px;
  width: fit-content;
  z-index: 2;
}
.roi-cal__day--nights::after {
  content: '';
  height: 0;
  margin-bottom: 1px;
  width: 0;
}
.roi-cal__nav {
  display: flex;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 5;
}
.roi-cal__prev,
.roi-cal__next {
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 20px;
  overflow: hidden;
  width: 20px;
}
.roi-cal__prev-text,
.roi-cal__next-text {
  display: none;
}
.roi-cal__prev:after,
.roi-cal__next:after {
  content: '';
  display: block;
  height: 12px;
  position: absolute;
  width: 12px;
}
.roi-cal__prev:after {
  transform: rotate(45deg);
}
.roi-cal__next:after {
  transform: rotate(-135deg);
}
.roi-cal__close {
  border: 0;
  cursor: pointer;
  font-size: 11px;
  margin-bottom: 20px;
  padding: 10px 20px;
}
.roi-cal--multiple {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
}
@media (min-width: 992px) {
  .roi-cal--multiple {
    width: 650px;
  }
}
.roi-cal--unselectable {
  cursor: default;
}
.roi-cal--checkin,
.roi-cal--checkout {
  font-weight: bold;
}
.roi-cal--overlay {
  overflow: hidden;
}
.roi-cal.show-calendar {
  opacity: 1;
  pointer-events: all;
  transition: all 500ms ease-out;
  visibility: visible;
  z-index: 10000;
}
.roi-cal.no-modal.roi-cal {
  height: 450px;
  overflow-y: auto;
  position: fixed;
  width: 360px;
}
@media (min-width: 992px) {
  .roi-cal.no-modal.roi-cal {
    height: fit-content;
    position: absolute;
  }
  .roi-cal.no-modal.roi-cal .roi-cal {
    padding-left: 7%;
    padding-right: 7%;
  }
  .roi-cal.no-modal.roi-cal .roi-cal__inner {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
  }
  .roi-cal.no-modal.roi-cal .roi-cal__day {
    line-height: 2.5;
  }
  .roi-cal.no-modal.roi-cal .roi-cal__close {
    bottom: 20px;
    position: absolute;
    z-index: 8;
  }
}
.roi-overlay-body {
  overflow: hidden;
}
.roi-overlay {
  background-color: var(--roi-overlay-color, rgba(0, 0, 0, 0.75));
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1200;
}
/*# sourceMappingURL=search-engine-styles-skeleton.css.map */